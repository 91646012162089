import React,{useEffect,useState} from "react";
import './App.css';
import prev from "./image/prev.png";
import next from "./image/next.png";
import americanflag from "./image/americanflag.jpg";
import franchflag from "./image/franchflag.png";
import { BrowserRouter, NavLink, Router } from 'react-router-dom';

export default function App() {
    const [allData, setAllData] = useState(null)
    const [previousLink, setPreviousLink] = useState(null)
    const [nextLink, setNextLink] = useState(null)
    const [imageCount, setImageCount] = useState(0)

        useEffect(() => {
            fetch("https://1480.freelancedeveloper.site/api/getPost/"+window.location.pathname.substring(1,window.location.pathname.length)) //from where to take data
                .then(response => response.json())// tacking out from JSON
                .then(data => {setAllData(data)})

            fetch("https://1480.freelancedeveloper.site/api/getPostPrevious/"+window.location.pathname.substring(1,window.location.pathname.length)) //from where to take data
                .then(response => response.json())// tacking out from JSON
                .then(data => {setPreviousLink(data)})

            fetch("https://1480.freelancedeveloper.site/api/getPostNext/"+window.location.pathname.substring(1,window.location.pathname.length)) //from where to take data
                .then(response => response.json())// tacking out from JSON
                .then(data => {setNextLink(data)})
        }, [])

        console.log(allData, "dat")
        return (
            <BrowserRouter>
                <div className="App">
                    <header>
                        <div className="header">
                            <div className="links-div">
                                <ul className="header-ul">
                                    <li>TECH</li>
                                    <li>TAP</li>
                                    <li>INC</li>
                                </ul>
                            </div>
                            <div className="dropdown">
                                <button className="dropbtn">English</button>
                                <div className="dropdown-content">
                                    <a href="#"><img src={americanflag}/></a>
                                    <a href="#"><img src={franchflag} className="franch-flag"/></a>
                                </div>
                            </div>
                        </div>
                    </header>
                    <main>
                        <div className="title">
                            <h2>{allData && allData.title}</h2>
                        </div>
                        <div className="main-img">
                            {
                               allData && allData.images.map(el=>
                                    <div className="img-link">
                                        <img src={el} alt='image'/>
                                    </div>)
                            }
                        </div>
                        {allData?.audio&&<div className="general-div"><audio controls>
                            <source src={allData['audio']} type={allData && 'audio/' + allData['audioExtension']}/>
                                    No audio support.
                        </audio></div>}

                        <div className="text-div">
                            <div className="text">
                                <p>{allData && allData.description}</p>
                            </div>
                        </div>
                        <div className="prev-next">
                            <div className="preview">
                                <span><a style={{
                                    color: 'black',
                                    textDecoration: 'none',
                                }} href={previousLink == null ? (allData ? allData['link'] : '#') : previousLink}><img src={prev} alt="img"/><p>Preview</p></a></span>
                            </div>
                            <div className="next">
                                <span><a style={{
                                    color: 'black',
                                    textDecoration: 'none',
                                }} href={nextLink == null ? (allData ? allData['link'] : '#') : nextLink}><p>Next</p><img src={next} alt="img"/></a></span>
                            </div>
                        </div>
                    </main>
                    <footer>
                        <ul className="footer-ul">
                            <li>T.O.S</li>
                            <li>SUPPORT</li>
                            <li>FAQs</li>
                        </ul>
                    </footer>
                </div>
            </BrowserRouter>

        )
    };
